/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/swiper@4.1.0/dist/css/swiper.min.css
 * - /npm/flag-icon-css@3.5.0/css/flag-icon.min.css
 * - /npm/bootswatch@3.3.7/yeti/bootstrap.min.css
 * - /npm/@unicorn-fail/drupal-bootstrap-styles@0.0.2/dist/3.3.0/7.x-3.x/drupal-bootstrap-yeti.min.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
